<template>
  <div id="component-tower-details-documents" class="content-with-sidebar">
    <div class="sidebar">
      <div class="sidebar-card">
        <h3 class="title">
          <i class="fas fa-users mr-2"></i>
          File List
        </h3>
        <ul
          class="nested-list"
          v-for="folder in filteredFolders"
          :key="folder.id"
        >
          <li>
            <div class="px-4 py-2">
              <img src="@/assets/icons/folder.png" alt="" class="mr-2" />
              {{ folder.name }}
            </div>
            <ul>
              <li
                v-for="doc in filteredDocuments(folder)"
                :key="doc.id"
                :class="{
                  'is-active': selected != null && selected.id == doc.id,
                }"
              >
                <a
                  href="#"
                  class="stretched-link"
                  @click.prevent="selected = doc"
                  >{{ doc.name }}</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="sidebar-card" v-if="selected">
        <h3 class="title">
          <i class="fas fa-users mr-2"></i>
          File Info
        </h3>
        <div class="p-4">
          <table class="table table-borderless table-sm mb-0">
            <tr>
              <td
                class="text-muted text-right font-italic font-weight-light"
                style="font-size: 12px"
              >
                File Name
              </td>
              <td class="pl-4">{{ selected.name }}</td>
            </tr>
            <tr>
              <td
                class="text-muted text-right font-italic font-weight-light"
                style="font-size: 12px"
              >
                Size
              </td>
              <td class="pl-4">
                {{ (selected.file.size / 1000).toFixed(2) }} MB
              </td>
            </tr>
            <tr>
              <td
                class="text-muted text-right font-italic font-weight-light"
                style="font-size: 12px"
              >
                MIME Type
              </td>
              <td class="pl-4">{{ selected.file.mime }}</td>
            </tr>
            <tr>
              <td
                class="text-muted text-right font-italic font-weight-light"
                style="font-size: 12px"
              >
                Uploaded At
              </td>
              <td class="pl-4 sm">{{ formatDate(selected.file.createdAt) }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="content p-4 align-self-stretch">
      <iframe
        v-if="selected && selected.file"
        :src="selected.file.url"
        frameborder="0"
        class="d-block w-100 h-100"
        @load="$parent.isLoading = false"
      ></iframe>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import Moment from "moment";

export default {
  name: "towerDetailsDocuments",
  props: ["tower"],
  data() {
    return {
      folders: [],
      documents: [],
      selected: null,
    };
  },
  computed: {
    filteredFolders() {
      return this.folders.filter(
        (folder) => this.filteredDocuments(folder).length > 0
      );
    },
    towerData() {
      const kv = [];
      Object.keys(this.tower.tower).forEach((key) => {
        if (
          [
            "id",
            "_id",
            "createdAt",
            "updatedAt",
            "__v",
            "site",
            "ancillariesLayoutImage",
          ].includes(key)
        )
          return;
        const result = key.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        kv.push({ k: finalResult, v: this.tower.tower[key] });
      });
      return kv;
    },
  },
  methods: {
    filteredDocuments(folder) {
      return this.documents.filter((doc) => doc.folder.id == folder.id);
    },
    formatDate(date) {
      return Moment(date).format("YYYY-MM-DD hh:mm A");
    },
  },
  async mounted() {
    this.$parent.isLoading = true;
    this.folders = (await API.get("folders")).data;
    this.documents = (await API.get(`documents?site=${this.tower.id}`)).data;
    this.selected = this.documents[0];
    this.$parent.isLoading = false;
  },
};
</script>